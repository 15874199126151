<template>
  <div class="create">
    <Header class="header"></Header>
    <div class="canvasView">
      <div class="previous" v-if="cancelList.length>=1" @click="withdraw"></div>
      <canvas ref="canvas" class="canvas"></canvas>
    </div>
    <div class="btn_container">
      <div class="main">
        <div class="icon" v-bind:class="{ enable: pencilStart }" @click="penSin">
          <img src="../assets/image/create/pencils.png" v-if="!pencilStart">
          <img src="../assets/image/create/pencil.png" v-else @click="sinBm=true"/>
        </div>
        <!--    <div class="icon bm" @click="sinBm=true">-->
        <!--    </div>-->
        <div class="icon" v-bind:class="{ enable: !pencilStart }" @click="rubSin">
          <img src="../assets/image/create/rubbers.png" v-if="pencilStart"/>
          <img src="../assets/image/create/rubber.png" v-else @click="sinBm=true"/>
        </div>
        <div class="color" :style="setcolors">
          <colorPicker v-model="setcolor" class="colorPicker"/>
          <!--  <verte picker="square" v-model="setcolor"></verte>-->
          <!--  <input type="color" v-model="setcolor">-->
        </div>
        <div class="icon" @click="openbac">
          <img src="../assets/image/create/bac.png"/>
          <div class="iconbg" :style="'background-color:'+background"></div>
        </div>
        <div class="icon">
          <img @touchstart="clearHandle" src="../assets/image/create/clear.png"/>
        </div>
        <div class="icon">
          <img @touchstart="saveImg" src="../assets/image/create/ok.png"/>
        </div>
      </div>
    </div>

    <div class="sinBm" v-if="sinBm">
      <div v-for="(item,index) in sizeBg" :key="index" class="boxbg" v-bind:class="{bright:item==lineWidth}">
        <div class="bcfn" :style="'width: '+(item+5)+'px;height: '+(item+5)+'px;'" @click="bm(item)"></div>
      </div>
    </div>
    <div class="sinBac" v-if="bacView">
      <div class="title">颜色背景更改</div>
      <div class="reset" @click="bacView=false"></div>
      <div class="main">
        <div class="block" @click="sinBac(item)" v-for="(item,index) in bacColors" :key="index"
             :style="'background-color:'+item">
        </div>
        <div class="bacnot" @click="sinBac('#fff')"></div>
      </div>
    </div>
    <div class="penName" v-if="penNameStart">
      <div class="main">
        <div class="reset" @click="closeName"></div>
        <div class="title">请输入作品名称</div>
        <input type="text" v-model="penName">
        <div class="ok" @click="setName">完成</div>
        <div class="f">注：作品名称不超过10个字数</div>
        <div class="f"></div>
      </div>
    </div>
    <load ref="load"></load>
  </div>
</template>

<script>
import Header from '../components/Header'
import {Dialog, Notify} from "vant";

// 获取设备的宽度和高度
const {clientWidth, clientHeight} = document.documentElement
import load from '../components/load'
import {historyput, put, historyfind} from '../api/create'

export default {
  data() {
    return {
      width: 360,
      height: 540,
      ctx: '',
      el: '',
      background: '#ffffff',
      finX: 0,
      finY: 0,
      setcolor: '#000',//颜色选择器
      setcolors: 'background-color:#000;',
      sin: false,
      sinBm: false,
      pencilStart: true,
      bacView: false,
      penNameStart: false,
      color: '#000',
      bacColors: ['#DB0000', '#FF7E00', '#FFEC00', '#88F254', '#06E5E6', '#265BEE', '#411AA6'],
      lineWidth: 3,
      lineCap: 'round',
      cancelList: [],
      penName: '',
      time: 0,
      timeStart: '',
      sizeBg: [2, 3, 5, 8, 13, 21],
    }
  },
  beforeDestroy() {
    const {el} = this
    let cancelList = this.cancelList

    clearInterval(this.seTtime)

    if (cancelList.length >= 1) {
      const imgBase64 = el.toDataURL("image/png")
      let userInfo = JSON.parse(JSON.stringify(this.$store.state.userInfo))
      delete userInfo.Collect
      delete userInfo.Popularity
      delete userInfo.imgUrl
      delete userInfo.Newtime
      delete userInfo.Uptime
      delete userInfo.Time

      historyput({imgBase64, cancelList, userInfo}).then()
    }
  },
  watch: {
    setcolor(value) {
      this.color = value
      this.setcolors = 'background-color:' + value + ';'
      this.setCanvas()
    }
  },
  mounted() {
    if (clientWidth >= 420) {
      Dialog.confirm({
        message: '请选择绘画设备\niPad/surface请选择 “手机”',
        confirmButtonText: '电脑',
        cancelButtonText: '手机'
      }).then(() => {
        let route = this.$route
        let query = route.query
        this.$router.push(
            {
              path: '/zju/createPc',
              query: {...query}
            }
        )
        this.historySet()
      }).catch(() => {
        this.historySet()
      });
    } else {
      this.historySet()
    }

    this.el = this.$refs.canvas
    this.ctx = this.el.getContext('2d')
    this.init()

    let width = (clientWidth - this.width) / 2
    let height = (clientHeight - this.height) / 2
    this.finX = width
    this.finY = height
  },
  methods: {
    bm(data) {
      const {ctx} = this
      this.lineWidth = Number(data)
      ctx.lineWidth = data;
      this.sinBm = false
    },
    getFromTime(str) {
      var dt = new Date(str),                 // 获取时间
          Y = dt.getFullYear(),            //获取 年 (四位)
          Mont = dt.getMonth() + 1,      //获取 月 (0-11,0代表1月)
          Day = dt.getDate(),               //获取 日 （1-31）
          Hous = dt.getHours(),         //获取 小时 (0-23)
          Min = dt.getMinutes(),          //获取 分(0-59)
          Sec = dt.getSeconds();       //获取秒 (0-59)
      return Y + '年' + Mont + '月' + Day + '日' + Hous + '时' + Min + '分' + Sec + '秒';
    },
    formatSeconds(value) {
      var secondTime = parseInt(value) // 秒
      var minuteTime = 0 // 分
      var hourTime = 0 // 小时
      var dayTime = 0 // 天
      var result = ''
      if (value < 60) {
        result = secondTime + '秒'
      } else {
        if (secondTime >= 60) { // 如果秒数大于60，将秒数转换成整数
          // 获取分钟，除以60取整数，得到整数分钟
          minuteTime = parseInt(secondTime / 60)
          // 获取秒数，秒数取佘，得到整数秒数
          secondTime = parseInt(secondTime % 60)
          // 如果分钟大于60，将分钟转换成小时
          if (minuteTime >= 60) {
            // 获取小时，获取分钟除以60，得到整数小时
            hourTime = parseInt(minuteTime / 60)
            // 获取小时后取佘的分，获取分钟除以60取佘的分
            minuteTime = parseInt(minuteTime % 60)
            if (hourTime >= 24) {
              // 获取天数， 获取小时除以24，得到整数天
              dayTime = parseInt(hourTime / 24)
              // 获取小时后取余小时，获取分钟除以24取余的分；
              hourTime = parseInt(hourTime % 24)
            }
          }
        }
        if (secondTime > 0) {
          secondTime = parseInt(secondTime) >= 10 ? secondTime : '0' + secondTime
          result = '' + secondTime + '秒'
        }
        if (minuteTime > 0) {
          minuteTime = parseInt(minuteTime) >= 10 ? minuteTime : '0' + minuteTime
          result = '' + minuteTime + '分' + result
        }
        if (hourTime > 0) {
          result = '' + parseInt(hourTime) + '小时' + result
        }
        if (dayTime > 0) {
          result = '' + parseInt(dayTime) + '天' + result
        }
      }
      return result
    },
    historySet() {
      let userInfo = JSON.parse(JSON.stringify(this.$store.state.userInfo))
      let _id = userInfo._id
      historyfind({_id}).then(res => {
        this.openTime()
        this.timeStart = this.getFromTime(new Date())
        if (res.code == 0) {
          let work = res.work
          if (work.cancelList) {
            let cancelList = JSON.parse(work.cancelList)
            let imgBase64 = work.imgBase64
            if (imgBase64) {
              Dialog.confirm({
                message: '绘画记录存在，是否要恢复吗？',
              }).then(() => {
                let img = new Image()
                img.src = imgBase64
                img.onload = (() => {
                  let el = this.$refs.canvas
                  let ctx = el.getContext('2d')
                  ctx.drawImage(img, 0, 0);
                  this.cancelList = cancelList
                })
              }).catch(() => {

              });
            }
          }
        }
      })
    },
    openTime() {
      this.seTtime = setInterval(() => {
        let time = this.time
        time++
        this.time = time
      }, 1000)
    },
    setName() {
      const {el} = this
      let penName = this.penName
      if (!penName) {
        Dialog.alert({
          message: '请输入给画名称',
        });
        return;
      }
      if (this.cancelList.length == 0) {
        Dialog.alert({
          message: '你未创作作品，无法提交',
        });
        return;
      }
      if (penName.length >= 10) {
        Dialog.alert({
          message: '作品名称超出10个字数',
        });
        return;
      }

      let timeStart = this.timeStart
      let timeEnd = this.getFromTime(new Date())
      let timeSum = this.formatSeconds(this.time)

      // let idNumber = this.$route.query.idNumber
      let SidNumber = this.$route.query.SidNumber
      let userInfo = JSON.parse(JSON.stringify(this.$store.state.userInfo))
      delete userInfo.Collect
      delete userInfo.Popularity
      delete userInfo.imgUrl
      delete userInfo.Newtime
      delete userInfo.Uptime
      delete userInfo.Time

      const imgBase64 = el.toDataURL("image/png")
      let terminal = navigator.userAgent


      this.$refs.load.open()
      put({
        imgBase64,
        userInfo,
        SidNumber,
        penName,
        terminal,
        timeStart,
        timeEnd,
        timeSum
      }).then(res => {
        let Prize = res.Prize
        let PrizeImage = res.PrizeImage

        this.$refs.load.close()
        if (res.code == 0) {
          Dialog.alert({
            message: res.message,
          }).then(() => {
            this.cancelList = []
            this.$router.push({
              path: '/zju/exhibit',
              query: {SidNumber, Prize, PrizeImage}
            })
            this.$root.$emit("init")
          });
        } else {
          Notify({type: 'warning', message: res.message});
        }
      }).catch(() => {
        this.$refs.load.close()
        Dialog.alert({
          message: '服务异常，请联系技术',
        })
      })
    },
    closeName() {
      this.penNameStart = false
      this.penName = ''
    },
    withdraw() {
      if (this.cancelList.length == 0) {
        alert('撤回次数到底')
      } else {
        const {ctx} = this
        let cancelList = this.cancelList
        let imgdata = cancelList.pop()
        let img = new Image()
        img.src = imgdata
        setTimeout(() => {
          ctx.drawImage(img, 0, 0);
          this.cancelList = cancelList
        }, 10)
      }
    },
    sinBac(str) {
      const {ctx, el} = this
      const imgBase64 = el.toDataURL("image/png")
      this.cancelList.push(imgBase64)
      this.bacView = false
      this.background = str
      ctx.fillStyle = str;
      ctx.fillRect(0, 0, this.width, this.height);
    },
    //初始化并清空画布
    initCanvas() {
      const {ctx} = this
      this.background = '#fff'
      this.cancelList = []
      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, this.width, this.height);
    },
    //背景色更换
    setCanvas() {
      const {ctx, lineWidth, color, lineCap} = this
      // 设置线条颜色
      ctx.strokeStyle = color
      // 设置线宽
      ctx.lineWidth = lineWidth
      // 设置线条两头的结束点和开始点是圆形的
      ctx.lineCap = lineCap
    },
    saveImg() {
      Dialog.confirm({
        message: '是否提交绘画？',
      }).then(() => {
        this.penNameStart = true
      }).catch(() => {
      });
    },
    // 清空
    clearHandle() {
      Dialog.confirm({
        message: '是否清空当前的画布？',
      }).then(() => {
        this.initCanvas()
        this.imgUrl = ''
        this.isDraw = false
      }).catch(() => {
      });
    },
    openbac() {
      this.bacView = true
    },
    penSin() {

      if (this.pencilStart == false) {
        this.sinBm = true
        this.color = this.setcolor
        this.setCanvas()
        this.pencilStart = true
      }
    },
    rubSin() {
      if (this.pencilStart == true) {
        this.sinBm = true
        this.color = this.background
        this.setCanvas()
        this.pencilStart = false
      }
    },
    init() {
      const {ctx, el, width, height, background, color, lineWidth} = this
      el.width = width;
      el.height = height;

      ctx.fillStyle = background
      ctx.fillRect(0, 0, this.width, this.height);


      ctx.strokeStyle = color;
      ctx.lineWidth = lineWidth;
      ctx.lineCap = 'round';


      el.addEventListener("touchstart", event => {
        this.sinBm = false
        const imgBase64 = el.toDataURL("image/png")

        let cancelList = this.cancelList
        cancelList.push(imgBase64)

        function unique(arr) {
          var tmp = new Array();
          for (var i in arr) {
            if (tmp.indexOf(arr[i]) == -1) {
              tmp.push(arr[i]);
            }
          }
          return tmp;
        }

        cancelList = unique(cancelList)
        this.cancelList = cancelList

        event.preventDefault();
        let e = event.changedTouches;
        if (e.length == 1) {
          let posX = e[0].clientX - this.finX;
          let posY = e[0].clientY - this.finY + 10;
          ctx.beginPath()
          ctx.moveTo(posX, posY);
        }

      })


      el.addEventListener('touchmove', event => {
        event.preventDefault();
        let e = event.changedTouches;
        if (e.length == 1) {
          let posX = e[0].clientX - this.finX;
          let posY = e[0].clientY - this.finY + 10;
          ctx.lineTo(posX, posY);
          ctx.stroke()
        }
      })
    }
  },
  components: {
    Header,
    load,
  }
}


</script>

<style lang="scss">
.create {
  width: 100%;
  height: 100%;
  position: relative;
  background: #ccc;
  display: flex;
  flex-flow: column;

  .header {
    width: 100%;
    background-color: #FFFFFF;
    z-index: 10;
  }

  .canvasView {
    width: 100%;
    height: 100%;
    position: relative;

    canvas {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -180px;
      margin-top: -270px;
    }

    .previous {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -250px;
      margin-left: -155px;
      background-image: url("../assets/image/create/previous.png");
      background-size: 100% 100%;
      width: 20px;
      height: 20px;
      z-index: 20;
    }
  }


  .btn_container {
    box-shadow: 0 2px 7px 0 rgba(159, 159, 159, 0.5);
    //border-top: 1px solid rgb(86, 70, 235);
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    z-index: 10;

    .main {
      width: 300px;
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;

      .icon {
        width: 35px;
        height: 35px;
        text-align: center;
        line-height: 43px;
        position: relative;

        .iconbg {
          position: absolute;
          width: 11.5px;
          height: 11.3px;
          right: 9px;
          top: 8.8px;
        }

        img {
          width: 20px;
          height: 20px;
          display: inline-block;
        }
      }

      .bm {
        background-color: #1989fa;
      }

      .color {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        background-color: #898989;


        .colorPicker {
          width: 100%;
          height: 100%;
          position: relative;
        }

        input {
          width: 20px;
          height: 20px;
          position: absolute;
          border-radius: 50px;
          opacity: 0;
        }
      }
    }
  }

  .sinBm {
    box-shadow: 0 2px 7px 0 rgba(159, 159, 159, 0.5);
    position: absolute;
    width: 280px;
    height: 50px;
    bottom: 75px;
    left: 50%;
    margin-left: -140px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .boxbg {
      display: flex;
      align-items: center;
      text-align: center;
      height: 30px;
      width: 30px;

      .bcfn {
        margin: 0 auto;
        background-color: #020202;
        border-radius: 50px;

      }
    }


    .bright {
      .bcfn {
        background-color: #973733;
      }
    }
  }

  .sinBac {
    box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
    position: absolute;
    width: 280px;
    height: 70px;
    bottom: 75px;
    left: 50%;
    margin-left: -140px;
    background-color: #ffffff;

    .reset {
      background-image: url("../assets/image/map/reset.png");
      background-size: 100% 100%;
      position: absolute;
      right: 10px;
      top: 5px;
      width: 15px;
      height: 15px;
    }

    .title {
      font-size: 12px;
      text-indent: 0.5rem;
      height: 30px;
      line-height: 30px;
    }

    .main {
      width: 100%;
      height: 35px;
      margin-top: 5px;
      display: flex;
      justify-content: space-around;

      .block {
        width: 25px;
        height: 25px;
      }

      .bacnot {
        width: 25px;
        height: 25px;
        background-size: 100% 100%;
        background-image: url("../assets/image/create/bacnot.png");
        border: 1px solid #000;
      }
    }
  }


  .penName {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .main {
      height: 180px;
      width: 300px;
      background-color: rgba(249, 249, 249, 0.97);
      box-shadow: 0 1px 8px 0 rgba(143, 143, 143, 0.5);
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -150px;
      margin-top: -105px;

      .title {
        width: 100%;
        height: 30px;
        font-size: 13px;
        text-align: center;
        //color: #898989;
        line-height: 30px;
      }

      input {
        display: block;
        margin: 0 auto;
        margin-top: 10px;
        border: none;
        width: 250px;
        height: 40px;
        outline: none;
        font-size: 14px;
        text-align: center;
      }

      .ok {
        width: 120px;
        height: 35px;
        line-height: 35px;
        background-color: #973733;
        color: #FFFFFF;
        text-align: center;
        margin: 0 auto;
        margin-top: 20px;
      }

      .reset {
        background-image: url("../assets/image/map/reset.png");
        background-size: 100% 100%;
        position: absolute;
        right: 10px;
        top: 5px;
        width: 15px;
        height: 15px;
      }

      .f {
        margin-top: 8px;
        font-size: 12px;
        color: #898989;
        text-indent: 1rem;
      }

    }

  }

  .box {
    top: -240px;
    left: -130px;
    width: 320px !important;

    h3 {
      display: none;
    }

    .tColor {
      //display: flex;
      li {
        width: 18px;
        height: 18px;
        margin-right: 12px;
      }
    }

    .bColor {
      margin-top: 10px;
      margin-bottom: -5px;

      li {
        width: 20px;
        margin-right: 10px;

        ul {

          li {
            margin-bottom: 10px;
            margin-right: 0;
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .hd {
      display: none;
    }

    //li{
    //  margin: 10px;
    //  width: 30px;
    //  height: 30px;
    //}
  }

  .colorBtn {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    z-index: 10;
    position: absolute;
    left: 3px;
    top: 5px;
  }
}


</style>
